import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style} from 'ol/style.js';

import counties from './data/counties.js';

// from https://www.nass.usda.gov/Research_and_Science/Crop-Sequence-Boundaries/metadata_Crop-Sequence-Boundaries-2023.htm
const crops = {
    "1": "Corn",
    "2": "Cotton",
    "3": "Rice",
    "4": "Sorghum",
    "5": "Soybeans",
    "6": "Sunflower",
    "10": "Peanuts",
    "11": "Tobacco",
    "12": "Sweet Corn",
    "13": "Pop or Orn Corn",
    "14": "Mint",
    "21": "Barley",
    "22": "Durum Wheat",
    "23": "Spring Wheat",
    "24": "Winter Wheat",
    "25": "Other Small Grains",
    "26": "Dbl Crop WinWht/Soybeans",
    "27": "Rye",
    "28": "Oats",
    "29": "Millet",
    "30": "Speltz",
    "31": "Canola",
    "32": "Flaxseed",
    "33": "Safflower",
    "34": "Rape Seed",
    "35": "Mustard",
    "36": "Alfalfa",
    "37": "Other Hay/Non Alfalfa",
    "38": "Camelina",
    "39": "Buckwheat",
    "41": "Sugarbeets",
    "42": "Dry Beans",
    "43": "Potatoes",
    "44": "Other Crops",
    "45": "Sugarcane",
    "46": "Sweet Potatoes",
    "47": "Misc Vegs & Fruits",
    "48": "Watermelons",
    "49": "Onions",
    "50": "Cucumbers",
    "51": "Chick Peas",
    "52": "Lentils",
    "53": "Peas",
    "54": "Tomatoes",
    "55": "Caneberries",
    "56": "Hops",
    "57": "Herbs",
    "58": "Clover/Wildflowers",
    "59": "Sod/Grass Seed",
    "60": "Switchgrass",
}

const category = {
    name: "County Crop History",
    details: `<a href="https://www.nass.usda.gov/Research_and_Science/Crop-Sequence-Boundaries/index.php">https://www.nass.usda.gov/Research_and_Science/Crop-Sequence-Boundaries/index.php</a>`,
    layers: [],
};

for (let [county, url] of Object.entries(counties)) {
    const geojsonSource = new VectorSource({
        url: url,
        format: new GeoJSON,
    });

    geojsonSource.on('featuresloadend', function(event) {
        event.features.forEach(feature => {
            for (let year = 2016; year <= 2023; year++) {
                const cropid = feature.get(`CDL${year}`);
                
                // Check if the value exists in the key, then replace it
                if (cropid in crops) {
                    feature.set(String(year), crops[cropid]);
                } else {
                    feature.set(String(year), cropid);
                }
                feature.unset(`CDL${year}`);
            }
        });
    });
    const vectorLayer = new VectorLayer({
        source: geojsonSource,
    });

    category.layers.push({
        name: county,
        layer: vectorLayer,
    });
}

category.layers.sort(function (a, b) {
    const a_state = a.name.substr(a.length - 2);
    const b_state = a.name.substr(b.length - 2);
    // Sort by state...
    if (a_state != b_state) {
        return a_state > b_state ? 1 : -1;
    }
    // ...then by county
    return a.name > b.name ? 1 : -1;
});

export default category;
