import countryKitchenLayer from './country-kitchen/layer.js';
import culversLayer from './culvers/layer.js';
import gilibertosLayer from './gilibertos/layer.js';
import krispyKremeLayer from './krispy-kreme/layer.js';
import kwikTripLayer from './kwik-trip/layer.js';
import menardsLayer from './menards/layer.js';
import michaelsLayer from './michaels/layer.js';
import milwaukeeBurgerCompanyLayer from './milwaukee-burger-company/layer.js';
import punchPizzaLayer from './punch-pizza/layer.js';
import raisingCanesLayer from './raising-canes/layer.js';
import waffleHouseLayer from './waffle-house/layer.js';
import whataburgerLayer from './whataburger/layer.js';

const chains = {
    name: "Chains",
    layers: [
        {
            name: "Country Kitchen",
            layer: countryKitchenLayer,
        },
        {
            name: "Culver's",
            layer: culversLayer,
        },
        {
            name: "Giliberto's Mexican Taco Shop",
            layer: gilibertosLayer,
        },
        {
            name: "Krispy Kreme",
            layer: krispyKremeLayer,
        },
        {
            name: "Kwik Trip/Kwik Star",
            layer: kwikTripLayer,
        },
        {
            name: "Menards",
            layer: menardsLayer,
        },
        {
            name: "Michaels",
            layer: michaelsLayer,
        },
        {
            name: "Milwaukee Burger Company",
            layer: milwaukeeBurgerCompanyLayer,
        },
        {
            name: "Punch Pizza",
            layer: punchPizzaLayer,
        },
        {
            name: "Raising Cane's",
            layer: raisingCanesLayer,
        },
        {
            name: "Waffle House",
            layer: waffleHouseLayer,
        },
        {
            name: "Whataburger",
            layer: whataburgerLayer,
        },
    ],
};

export default chains;
