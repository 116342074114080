import county27021 from './27021.geojson?url';
import county27057 from './27057.geojson?url';
import county27071 from './27071.geojson?url';
import county27137 from './27137.geojson?url';
import county27061 from './27061.geojson?url';
import county27083 from './27083.geojson?url';
import county27101 from './27101.geojson?url';
import county27043 from './27043.geojson?url';
import county27013 from './27013.geojson?url';
import county27161 from './27161.geojson?url';
import county27163 from './27163.geojson?url';
import county27123 from './27123.geojson?url';
import county27165 from './27165.geojson?url';
import county27127 from './27127.geojson?url';
import county27035 from './27035.geojson?url';
import county27097 from './27097.geojson?url';
import county27049 from './27049.geojson?url';
import county27157 from './27157.geojson?url';
import county27169 from './27169.geojson?url';
import county27091 from './27091.geojson?url';
import county27011 from './27011.geojson?url';
import county27073 from './27073.geojson?url';
import county27151 from './27151.geojson?url';
import county27063 from './27063.geojson?url';
import county27105 from './27105.geojson?url';
import county27033 from './27033.geojson?url';
import county27173 from './27173.geojson?url';
import county27081 from './27081.geojson?url';
import county27117 from './27117.geojson?url';
import county27119 from './27119.geojson?url';
import county27017 from './27017.geojson?url';
import county27015 from './27015.geojson?url';
import county27167 from './27167.geojson?url';
import county27051 from './27051.geojson?url';
import county27155 from './27155.geojson?url';
import county27087 from './27087.geojson?url';
import county27029 from './27029.geojson?url';
import county27115 from './27115.geojson?url';
import county27001 from './27001.geojson?url';
import county27095 from './27095.geojson?url';
import county27065 from './27065.geojson?url';
import county27023 from './27023.geojson?url';
import county27067 from './27067.geojson?url';
import county27129 from './27129.geojson?url';
import county27085 from './27085.geojson?url';
import county27143 from './27143.geojson?url';
import county27147 from './27147.geojson?url';
import county27103 from './27103.geojson?url';
import county27133 from './27133.geojson?url';
import county27007 from './27007.geojson?url';
import county27109 from './27109.geojson?url';
import county27039 from './27039.geojson?url';
import county27059 from './27059.geojson?url';
import county27003 from './27003.geojson?url';
import county27053 from './27053.geojson?url';
import county27131 from './27131.geojson?url';
import county27089 from './27089.geojson?url';
import county27113 from './27113.geojson?url';
import county27107 from './27107.geojson?url';
import county27027 from './27027.geojson?url';
import county27093 from './27093.geojson?url';
import county27069 from './27069.geojson?url';
import county27079 from './27079.geojson?url';
import county27171 from './27171.geojson?url';
import county27019 from './27019.geojson?url';
import county27077 from './27077.geojson?url';
import county27135 from './27135.geojson?url';
import county27047 from './27047.geojson?url';
import county27045 from './27045.geojson?url';
import county27099 from './27099.geojson?url';
import county27141 from './27141.geojson?url';
import county27145 from './27145.geojson?url';
import county27041 from './27041.geojson?url';
import county27125 from './27125.geojson?url';
import county27025 from './27025.geojson?url';
import county27055 from './27055.geojson?url';
import county27111 from './27111.geojson?url';
import county27139 from './27139.geojson?url';
import county27037 from './27037.geojson?url';
import county27005 from './27005.geojson?url';
import county27121 from './27121.geojson?url';
import county27149 from './27149.geojson?url';
import county27031 from './27031.geojson?url';
import county27075 from './27075.geojson?url';
import county27159 from './27159.geojson?url';
import county27153 from './27153.geojson?url';
import county27009 from './27009.geojson?url';

export default {
    'Cass County, MN': county27021,
    'Hubbard County, MN': county27057,
    'Koochiching County, MN': county27071,
    'St. Louis County, MN': county27137,
    'Itasca County, MN': county27061,
    'Lyon County, MN': county27083,
    'Murray County, MN': county27101,
    'Faribault County, MN': county27043,
    'Blue Earth County, MN': county27013,
    'Waseca County, MN': county27161,
    'Washington County, MN': county27163,
    'Ramsey County, MN': county27123,
    'Watonwan County, MN': county27165,
    'Redwood County, MN': county27127,
    'Crow Wing County, MN': county27035,
    'Morrison County, MN': county27097,
    'Goodhue County, MN': county27049,
    'Wabasha County, MN': county27157,
    'Winona County, MN': county27169,
    'Martin County, MN': county27091,
    'Big Stone County, MN': county27011,
    'Lac qui Parle County, MN': county27073,
    'Swift County, MN': county27151,
    'Jackson County, MN': county27063,
    'Nobles County, MN': county27105,
    'Cottonwood County, MN': county27033,
    'Yellow Medicine County, MN': county27173,
    'Lincoln County, MN': county27081,
    'Pipestone County, MN': county27117,
    'Polk County, MN': county27119,
    'Carlton County, MN': county27017,
    'Brown County, MN': county27015,
    'Wilkin County, MN': county27167,
    'Grant County, MN': county27051,
    'Traverse County, MN': county27155,
    'Mahnomen County, MN': county27087,
    'Clearwater County, MN': county27029,
    'Pine County, MN': county27115,
    'Aitkin County, MN': county27001,
    'Mille Lacs County, MN': county27095,
    'Kanabec County, MN': county27065,
    'Chippewa County, MN': county27023,
    'Kandiyohi County, MN': county27067,
    'Renville County, MN': county27129,
    'McLeod County, MN': county27085,
    'Sibley County, MN': county27143,
    'Steele County, MN': county27147,
    'Nicollet County, MN': county27103,
    'Rock County, MN': county27133,
    'Beltrami County, MN': county27007,
    'Olmsted County, MN': county27109,
    'Dodge County, MN': county27039,
    'Isanti County, MN': county27059,
    'Anoka County, MN': county27003,
    'Hennepin County, MN': county27053,
    'Rice County, MN': county27131,
    'Marshall County, MN': county27089,
    'Pennington County, MN': county27113,
    'Norman County, MN': county27107,
    'Clay County, MN': county27027,
    'Meeker County, MN': county27093,
    'Kittson County, MN': county27069,
    'Le Sueur County, MN': county27079,
    'Wright County, MN': county27171,
    'Carver County, MN': county27019,
    'Lake of the Woods County, MN': county27077,
    'Roseau County, MN': county27135,
    'Freeborn County, MN': county27047,
    'Fillmore County, MN': county27045,
    'Mower County, MN': county27099,
    'Sherburne County, MN': county27141,
    'Stearns County, MN': county27145,
    'Douglas County, MN': county27041,
    'Red Lake County, MN': county27125,
    'Chisago County, MN': county27025,
    'Houston County, MN': county27055,
    'Otter Tail County, MN': county27111,
    'Scott County, MN': county27139,
    'Dakota County, MN': county27037,
    'Becker County, MN': county27005,
    'Pope County, MN': county27121,
    'Stevens County, MN': county27149,
    'Cook County, MN': county27031,
    'Lake County, MN': county27075,
    'Wadena County, MN': county27159,
    'Todd County, MN': county27153,
    'Benton County, MN': county27009,
};
